import styled from "styled-components";
import { mobile, tablet } from "../responsive";


const Container = styled.div`
    font-family: "Jost-SemiBold";
    height: 40vh;
    background-color: #25284b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${mobile({ padding: "0 2rem 0 2rem", height: "60vh" })}
    ${tablet({ padding: "0 2rem 0 2rem", height: "60vh" })}
`;
const Title = styled.h1`
    font-family: Jost-Bold;
    letter-spacing: 0.3rem;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    ${mobile({ fontSize: "1.6rem", margin: "0px" })}
    ${tablet({ fontSize: "1.2rem", margin: "0px" })}
`;

const Desc = styled.div`
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
    ${mobile({ textAlign: "center", fontSize: "1rem", margin: "20px 0" })}
    ${tablet({ textAlign: "center", fontSize: "1rem", margin: "20px 0" })}
`;
const Button = styled.button`
    width: 20%;
    margin: 25px 0;
    padding: 15px;
    border: 2px solid #1f2f4d;
    background-color: #ff6249;
    color: white;
    cursor: pointer;
    font-family: Jost-SemiBold;

    &:hover {
        background-color: white;
        border: 2px solid transparent;
        color: #ff6249;
    }
    ${mobile({ width: "100%", margin: "1rem 0" })}
    ${tablet({ width: "100%", margin: "1rem 0" })}
`;

const Newsletter = () => {
    return (
        <Container>
            <Title>Contact Us</Title>
            <Desc>
                We are thrilled to invite you to drop us a message on whatsapp.
            </Desc>
            <Desc>
                This is your chance to stay connected and contact us directly if you have any enquiries.
            </Desc>
            <Button onClick={() => window.open("https://api.whatsapp.com/send?phone=60166223451", "_blank") }>
                Whatsapp Us
            </Button>
        </Container>
    );
};

export default Newsletter;
